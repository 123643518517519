/*! Footer Bar Configuration and Styling */
@use "./foundation/var" as *;
@use "./mixins/phone-presets" as *;

#footer-bar {
	margin-top: auto;
	background-color: $scheme-background-gray;

	#footer-content {
		padding: 0.5rem 1.5rem 1.5rem;
		flex-shrink: 1;

		li#development-and-support-notice {
			color: white;
			font-size: 0.8rem;
			margin: 0.5rem 0 0 0;
		}

		li#footer-logos {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 0 1rem;

			#htlleonding-logo {
				margin: 1rem;
				height: 2.5rem;
			}

			#jku-logo {
				height: 1.6rem;
				margin: 1.45rem;
			}
		}

		ul#footer-bar-list li {
			margin-left: 2rem;
			margin-right: 2rem;
			font-weight: 400;
		}

		li#copyright {
			border-top: 1px solid #f4f4f4;
			margin-top: 1em;
			padding-top: 1rem;

			small {
				display: flex;
			}
		}
	}
}

/* For phones */
@media screen and (max-width: $small-screen-size) {
	#footer-bar {
		width: $phone-content-width;

		#footer-content {
			padding: 0.5rem 1rem 1rem;
			width: calc($phone-content-width - 2rem);

			li#footer-logos {
				& > #htlleonding-logo,
				& > #jku-logo {
					height: unset;
					width: 12rem;
					margin: 1.5rem;
				}
			}

			ul#footer-bar-list {
				flex-direction: column;
			}

			li#copyright {
				display: flex;
				border: none;
				align-items: center;
				justify-content: center;
				padding: 0;
			}
		}
	}
}
